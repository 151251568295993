<template>
  <div>
    <form @submit.prevent="formsend">
      <div class="col s12 card-element">
        <label>Card Number</label>
        <div id="card-number-element" class="input-value"></div>
      </div>

      <div class="col s6 card-element">
        <label>Expiry Date</label>
        <div id="card-expiry-element"></div>
      </div>

      <div class="col s6 card-element">
        <label>CVC</label>
        <div id="card-cvc-element"></div>
      </div>

      <!--<input type="text" id="card-number-element" ref="card" placeholder="Kartennummer" @change="setValidationError">
      <input type="text" id="card-expiry-element" ref="expiry" placeholder="expiry" @change="setValidationError">
      <input type="text" id="card-cvc-element" ref="cvc" placeholder="cvc" @change="setValidationError">-->
      <input type="submit" @click="placeOrderButtonPressed" value="process">
      <div class="error red center-align white-text">
         {{stripeValidationError}}
      </div>
    </form>
  </div>
</template>

<script>

const fb = require('@/store/firebaseConfig.js')

export default {
  name: "CreditCard",
  data() {
    return {
      stripe: null,
      cardNumberElement:null,
      cardExpiryElement:null,
      cardCVCElement:null,
      stripeValidationError: null,
      amount: '20'
    }
  },
  mounted() {
    this.stripe = Stripe("pk_test_obEAN0Oz9w57PLwXvItZFKq900F3k6RqgZ")
    this.createAndMountFormElements()
  },
  methods: {
    formsend() {
      console.log("prevented successful")
    },
    createAndMountFormElements() {
      var elements = this.stripe.elements();
      this.cardNumberElement = elements.create("cardNumber");
      this.cardNumberElement.mount("#card-number-element");
      this.cardExpiryElement = elements.create("cardExpiry");
      this.cardExpiryElement.mount("#card-expiry-element");
      this.cardCvcElement = elements.create("cardCvc");
      this.cardCvcElement.mount("#card-cvc-element");
      this.cardNumberElement.on("change", this.setValidationError);
      this.cardExpiryElement.on("change", this.setValidationError);
      this.cardCvcElement.on("change", this.setValidationError);
    },
    setValidationError(event) {
      console.log("im validating")
      this.stripeValidationError = event.error ? event.error.message : "";
    },
    placeOrderButtonPressed() {
      this.stripe.createToken(this.cardNumberElement).then(result => {
        if (result.error) {
          this.stripeValidationError = result.error.message;
        } else {
          var stripeObject = {
            amount: this.amount,
            source: result.token
          }

          this.saveDataToFireStore(stripeObject)
        }
      });
    },
    saveDataToFireStore(stripeObject) {
      const chargesRef = fb.db.collection("charges")
      const pushId = chargesRef.doc().id
      fb.db.collection("charges").doc(pushId).set(stripeObject)
      console.log("stage 1")
      chargesRef.doc(pushId).onSnapshot(snapShot => {
        console.log("stage 2")

        const charge = snapShot.data();
        console.log(charge)
        if (charge.error) {
          alert(charge.error);
          chargesRef
              .doc(pushId)
              .delete();
          return;
        }
        if (charge.status && charge.status == "succeeded") {
          alert(charge.status);
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>