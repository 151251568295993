<template>
  <div class="search">
    <back></back>
    <div class="searchform">
      <h1>Profile</h1>
      <br>
      <br>
      <h4>Username: <b-badge>{{$store.state.user.username}}</b-badge></h4>
      <h4>E-Mail: <b-badge>{{$store.state.user.email}}</b-badge></h4>
      <h4>Abo: <b-badge>{{ abo($store.state.user.pro) }}</b-badge> <b-button @click="cancelSub">Künden</b-button></h4>
    </div>
  </div>
</template>

<script>
const fb = require('../store/firebaseConfig.js')

export default {
  name: 'profile',
  data() {
    return {
    }
  },
  mounted () {
    this.$store.commit('setViewTo50')
    console.log(this.$store.state);

  },
  methods: {
    abo(bool) {
      if (bool) {
        return "Ja";
      } else {
        return "Nein";
      }
    },
    cancelSub() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer sk_test_Yq7Pimbo0xJoqhFycFY82yWA00WAg9Y8Gj");

      var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch("https://api.stripe.com/v1/subscriptions/" + this.$store.state.user.subscription, requestOptions)
          .then(response => response.text())
          .then(result => {

            console.log(result)
          })
          .catch(error => console.log('error', error));
    }
  },
}
</script>

<style>
.search {
    width: 60%;
    margin: 0 auto;
    text-align: center;
}
</style>
