<template>
  
</template>

<script>
export default {
    name: 'info',
    computed: {
        text() {
            i
            return this.data 
        }
    },
}
</script>

<style>

</style>