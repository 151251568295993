<template>
  <div class="learn">
    <back></back>
    <h1>Neue Aktionen hinzufügen</h1>
    <form class="form-new" @submit.prevent="entryActions">
      <div class="form-group">
        <label for="listname">Name *:</label>
        <input type="text" class="form-control" id="listname" v-model="list.name" required>
      </div>

      Pro Linie eine Aktion *:<br>
      <textarea rows="10" cols="60" class="form-control" v-model="terms" required></textarea><br>
      <button type="submit" class="btn btn-primary" v-if="!loading">Eintragen</button>
      <div class="spinner-border" role="status" v-if="loading">
          <span class="sr-only">Loading...</span>
      </div>
    </form>
    <p v-if="error">{{error}}</p>
  </div>
</template>

<script>
const fb = require('../store/firebaseConfig.js')

export default {
  name: 'newlist',
  data() {
    return {
      selectFilled: false,
      loading: false,
      terms: '',
      list: {
        name: '',
        owner: this.$store.state.uid,
        actions: [],
        created: Date.now(),
      },
      error: ''
    }
  },
  watch: {
    terms(newValue, oldValue) {
      this.list.actions = this.terms.split('\n')
      this.list.actions = this.list.actions.map(ele => {
        return ele.trim()
      })
      this.list.actions = this.list.actions.filter(ele => {
        if (ele === "") {
          return false
        }
        return true
      })
    }
  },
  methods: {
    entryActions() {
      this.error = '';
      if (this.list.name.trim() !== '' && this.list.actions.length >= 3) {
        this.loading = true
        this.$store.dispatch('addActionList', this.list).then(() => {
          this.$router.push('dashboard');
          this.loading = false
        }).catch(() => {
          this.error = "Leider klappte etwas beim Eintragen nicht.";
          this.loading = false
        })
      } else {
        this.error = "Bitte einen Namen, eine Liste und mindestens zwei Begriffe angeben!";
      }
    },
  },
}
</script>

<style>
.form-new {
  width: 80%;
  margin: 0 auto;
}
</style>
