<template>
  <div class="learn">
    <back v-if="this.$store.state.loggedIn"></back>
    <div class="learn-title">
      <h5>{{name}}</h5>
    </div>
    <div class="term">
      <span class="word" :class="{hidden: transition}">{{term}}</span>
    </div>
    <div class="action">
      <span class="word" :class="{hidden: transition}">{{action}}</span>
    </div>
    <div class="notes">
      <textarea v-model="notes"></textarea>
    </div>
    <button class="btn btn-primary" @click="random">Zufall</button><br><br>
    <button class="btn btn-outline-primary" @click="copy" v-if="copyable && !loadCopyToOwnCollection && success == ''">Übung in meine Sammlung kopieren</button>
    <div class="spinner-border" role="status" v-if="loadCopyToOwnCollection">
      <span class="sr-only">Loading...</span>
    </div>
    <br>
    <div v-if="error" class="alert alert-danger">{{error}}</div>
    <br v-if="error">
    <div v-if="success" class="alert alert-success">{{success}}</div>
    <br v-if="success">
    <hr>
    <br>
    <the-share-exercise></the-share-exercise>
    <!-- <button @click="$router.push('/dashboard')" v-if="$store.state.loggedIn">zurück</button> -->
  </div>
</template>

<script>
const fb = require('../store/firebaseConfig.js')
import TheShareExercise from '../components/TheShareExercise';
import { mapState } from 'vuex';

export default {
  name: 'learn',
  components: {
    TheShareExercise,
  },
  data() {
    return {
      currentList: [],
      currentActionList: [],
      term: '',
      action: '',
      name: '',
      notes: '',
      transition: false,
      copyable: false,
      error: '',
      success: '',
      loadCopyToOwnCollection: false
    }
  },
  computed: mapState(['lists']),
  mounted () {
    this.$store.commit('setViewTo80')

    if (this.$route.params.id == 'undefined') {
      location.assign('/dashboard');
    }

    if (!this.$store.state.loggedIn) {
      this.$store.commit('switchBanner', true);
    }

    if (this.$store.state.loggedIn === false || this.$root.payment === true) {
      console.log("Anonym")
      this.$store.dispatch('fetchAnonymousExercise', this.$route.params.id).then(() => {
        this.makeDataReady();
        this.random()
        console.log(this.currentList)
        this.name = this.currentList.name
      }).catch(error => {
        this.error = 'Es trat einen Verbindungsfehler auf. Bitte entschulige. Dieser könnte aber eingeloggt nicht mehr auftreten.';
      });
      return true;
    } else {
      console.log("Bekannt")
      this.$store.dispatch('fetchList', this.$store.state.uid).then(() => {
        this.$store.dispatch('fetchActions', this.$store.state.uid).then(() => {
          if (!this.isOwnList(this.$route.params.id)) {
            console.log('logged in but not my list');
            this.$store.dispatch('fetchTemporaryExercise', this.$route.params.id).then(() => {
              this.copyable = true;
              this.makeDataReady();

              if (this.currentList !== undefined) {
                this.random();
                this.name = this.currentList.name
              } else {
                //this.$router.push('/dashboard')
              }
            }).catch(error => {
              this.error = 'Leider konnte die fremde Liste nicht geladen werden. Versuche es später erneut.';
            })
          }

          this.$nextTick(() => {
            this.makeDataReady();

            if (this.currentList !== undefined) {
              this.random()
              this.name = this.currentList.name
            } else {
              //this.$router.push('/dashboard')
            }
          })
        })
      })
    }
  },
  watch: {
    lists(newValue, oldValue) {
      this.makeDataReady();
      if (this.currentList !== undefined) {
        this.random()
      }
    }
  },
  methods: {
    random() {
      this.transition = true;
      setTimeout(() => {
        this.transition = false;
        this.randomTerm();
        this.randomActions();
        this.notes = '';
      }, 400)
    },
    randomTerm() {
      let word = Math.floor((Math.random() * this.currentList.value.length));
      this.term = this.currentList.value[word];
    },
    randomActions() {
      let word = Math.floor((Math.random() * this.currentActionList.actions.length));
      this.action = this.currentActionList.actions[word];
    },
    copy() {
      this.success = '';
      this.error = '';
      this.loadCopyToOwnCollection = true;
      this.$store.dispatch('copyListReferenceToOwnCollection', this.$route.params.id).then(() => {
        this.loadCopyToOwnCollection = false;
        this.success = 'Die Übung wurde erfolgreich in deine Sammlung kopiert!';
      }).catch(() => {
        this.loadCopyToOwnCollection = false;
        this.error = 'Leider gab es einen Fehler, versuche es später erneut!';
      })
    },
    makeDataReady() {
      this.currentList = this.$store.state.lists.find(itm => {
        if (this.$route.params.id == itm.id) {
          return true;
        }
        return false;
      })

      this.currentActionList = this.$store.state.actions.find(itm => {
        console.log(itm)
        if (this.currentList.list == itm.id) {
          return true;
        }
        return false;
      })
    },
    isOwnList(listId) {
      let test = false;
      this.$store.state.lists.forEach(itm => {
        console.log(listId, itm.id);

        if (listId == itm.id) {
          test = true;
        }
      })
      return test;
    }
  },
}
</script>

<style lang="scss" scoped>

.learn {

  .learn-title {
    text-transform: uppercase;
    margin-bottom: 20px;
  }
}

.term {
  width: 80%;
  margin-top: 20px;
  border: 2px solid #12dafc;
  margin: 0 auto;
  margin-top: 40px;
  padding: 40px;
  border-radius: 20px;
  position: relative;
  font-size: 3rem;

  @include mobile-down {
    font-size: 1.8rem;
  }

  &::before {
    content: "Begriff";
    top: -15px;
    left: 40px;
    height: 30px;
    color: #12dafc;
    background-color: white;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    font-size: 1rem;
  }
}

.action {
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 80%;
  border: 2px solid lightgray;
  padding: 40px;
  border-radius: 20px;
  position: relative;

  &::before {
    content: "Aktion";
    top: -15px;
    left: 40px;
    height: 30px;
    color: lightgray;
    background-color: white;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
  }
}

.notes {
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 80%;
  border: 2px solid #FFD74F;
  padding: 40px;
  border-radius: 20px;
  position: relative;

  textarea {
    width: 100%;
    height: 100px;
  }

  &::before {
    content: "Notizen (werden nicht gespeichert oder ausgewertet)";
    top: -15px;
    left: 40px;
    height: 30px;
    color: #FFD74F;
    background-color: white;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
  }
}

.word {
  opacity: 1;
  transition: 0.4s ease all;

  &.hidden {
    opacity: 0;
  }
}

</style>
