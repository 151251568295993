<template>
    <div class="login">
        <div class="login-area">
          <h1>Login</h1>
            <p>Logge dich ein, um auf deine Übungen Zugriff zu erahlten.</p>
            <form @submit.prevent="loginsubmit">
                    <div class="form-group">
                        <label for="email">E-Mail</label>
                        <input type="text" v-model="email" id="email" name="email" class="form-control" required>
                    </div>
                    <div class="form-group">
                        <label for="passwort">Passwort</label>
                        <input type="password" v-model="password" id="passwort" name="password" class="form-control" required>
                    </div>
                    <input type="submit" name="sub" value="Login" class="btn btn-primary" v-if="!loading" :disabled="loading"><br>
                    <div class="spinner-border" role="status" v-if="loading">
                        <span class="sr-only">Loading...</span>
                    </div>
            </form>
            <br>
            <div v-if="error" class="alert alert-danger">{{error}}</div>
        </div>
        
        <div class="login-register">
            <h4>Registrieren</h4>
            <p>Hast du noch keinen Account?</p>
            <router-link to="register" class="btn btn-primary">Registrieren</router-link>
        </div>
    </div>
</template>

<script>
const fb = require('../store/firebaseConfig.js')

export default {
    name: 'login',
    data() {
        return {
            email: '',
            password: '',
            error: '',
            loading: false
        }
    },
    mounted () {
        this.$store.commit('setViewTo80')
        fb.auth.onAuthStateChanged(user => {
            if (user) {
                this.$router.push('/dashboard');
            }
        })
    },
    methods: {
        loginsubmit() {
            this.loading = true;
            this.error = '';
            fb.auth.signInWithEmailAndPassword(this.email, this.password)
                .then(data => {
                    console.log(data);
                    if (data.user !== null) {
                        this.$store.dispatch('logInUser', data.user.uid)
                        this.$router.push('/dashboard');
                    }
                })
                .catch(err => {
                    this.error = err.message;
                    this.password = '';
                    this.loading = false;
                });
        }
    },
}
</script>

<style lang="scss">
.login {
    width: 90%;
    margin: 0 auto;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;

    @include mobile-down {
        flex-direction: column;
    }
}

.login-area {
    width: 60%;
    padding-right: 5%;
    padding-bottom: 40px;
    border-right: gray solid 1px;
    
    @include mobile-down {
        border: none;
        
        border-bottom: gray solid 1px;
        width: 90%;
        padding: 0;
        padding-bottom: 20px;
    }
}

.login-register {
    width: 40%;
    margin-left: 5%;

    @include mobile-down {
        text-align: left;
        width: 90%;
        margin-top: 20px;
        margin-left: 0;
    }
}

</style>