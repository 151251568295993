<template>
    <div class="login">
        <div class="login-area">
          <h1>Registrieren</h1>
            <p>Bitte fülle alle Felder aus, um dich bei Deeperly zu registrieren.</p>
            <form @submit.prevent="register">
                    <div class="form-group">
                        <label for="username">Username *</label>
                        <input type="text" v-model="username" id="username" name="username" class="form-control" required>
                    </div>
                    <div class="form-group">
                        <label for="email">E-Mail *</label>
                        <input type="text" v-model="email" id="email" name="email" class="form-control" required>
                    </div>
                    <div class="form-group">
                        <label for="passwort">Passwort *</label>
                        <input type="password" v-model="password" id="passwort" name="password" class="form-control" required>
                    </div>
                    <div class="form-group">
                        <label for="password2">Passwort wiederholen *</label>
                        <input type="password" v-model="password2" id="password2" name="password2" class="form-control" required>
                    </div>
                    <input type="submit" name="sub" value="Registrieren" class="btn btn-primary" v-if="!loading" :disabled="loading"><br>
                    <div class="spinner-border" role="status" v-if="loading">
                        <span class="sr-only">Loading...</span>
                    </div>
            </form>
            <br>
            <div v-if="error" class="alert alert-danger">{{error}}</div>
            <hr>
            <p>Du hast schon einen Account? Bitte logge dich ein!</p>
            <router-link to="/login" class="btn btn-outline-primary">Login</router-link>
        </div>
    </div>
</template>

<script>
const fb = require('../store/firebaseConfig.js')

export default {
    name: 'login',
    data() {
        return {
            email: '',
            username: '',
            password: '',
            password2: '',
            error: '',
            loading: false
        }
    },
    mounted () {
        this.$store.commit('setViewTo50')
    },
    methods: {
        register() {
            if (this.password == this.password2) {
                this.loading = true;
                this.error = '';
                // Registrieren
                fb.auth.createUserWithEmailAndPassword(this.email, this.password)
                    .then(data => {
                        // User Profile updaten
                        data.user.updateProfile({
                                displayName: this.username,
                            })
                            .then(() => {
                                // Email Verification
                                const currentUser = fb.auth.currentUser;
                                currentUser.sendEmailVerification();

                                // User-Tabelle hinzufügen
                                let payment = Date.now() + (60 * 60 * 24 * 7);
                                fb.db.collection("users").doc(currentUser.uid).set({
                                    username: this.username,
                                    email: this.email,
                                    everPayed: false,
                                    nextPayment: payment
                                })
                                .then(() => {
                                    fb.auth.signInWithEmailAndPassword(this.email, this.password).then(data => {
                                        console.log(data);
                                        if (data.user !== null) {
                                            this.$store.dispatch('logInUser', data.user.uid)
                                            this.$router.push('/dashboard');
                                        }
                                    })
                                    .catch(err => {
                                        this.error = err.message;
                                        this.password = '';
                                        this.loading = false;
                                    });
                                })
                                .catch((error) => {
                                    this.error = "Error writing additional data."
                                });
                            });
                    })
                    .catch((error) => {
                        this.error = error.message;
                        this.password = '';
                        this.password2 = '';
                        this.loading = false;
                    });
            } else {
                this.error = 'Leider stimmen die bieden Passwörter nicht überein.';
            }
        }
    },
}
</script>

<style lang="scss">
.login {
    width: 90%;
    margin: 0 auto;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;

    @include mobile-down {
        flex-direction: column;
    }
}

.login-area {
    width: 100%;
    padding-right: 5%;
    padding-bottom: 40px;
    border-right: gray solid 0px;
}

.login-register {
    width: 40%;
    margin-left: 5%;

    @include mobile-down {
        text-align: left;
        width: 90%;
        margin-top: 20px;
        margin-left: 0;
    }
}

</style>