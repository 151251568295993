<template>
  <stripe-checkout
      ref="checkoutRef"
      :pk="publishableKey"
      :items="items"
      :successUrl="successUrl"
      :cancelUrl="cancelUrl"
  >
    <template slot="checkout-button">
      <button @click="checkout">Shut up and take my money!</button>
    </template>
  </stripe-checkout>
</template>

<script>
import { StripeCheckout } from 'vue-stripe-checkout';
export default {
  components: {
    StripeCheckout
  },
  data: () => ({
    loading: false,
    publishableKey: "pk_test_obEAN0Oz9w57PLwXvItZFKq900F3k6RqgZ",
    items: [
      {
        plan: "plan_Hq1lIGPKdJWf3W",
        quantity: 1
      }
    ],
    successUrl: 'http://localhost:8080/success?session_id={CHECKOUT_SESSION_ID}', // TODO: URLs ändern!
    cancelUrl: 'http://localhost:8080/payment/error',
  }),
  methods: {
    checkout () {
      this.$refs.checkoutRef.redirectToCheckout();
    }
  }
}
</script>