<template>
  <div class="learn">
    <back></back>
    <h1>Neue Liste</h1>
    <form class="form-new" @submit.prevent="entryExercise">
      <div class="form-group">
        <label for="listname">Name *:</label>
        <input type="text" class="form-control" id="listname" v-model="list.name" required>
      </div>

      <div class="form-group">
        <label for="listdesc">Beschreibung:</label>
        <input type="text" class="form-control" id="listdesc" v-model="list.description">
      </div>

      <div class="form-group">
        <label for="listbesch">Aktionen *:</label>
        <select v-model="list.list" class="form-control" id="listbesch" ref="actionselect" required></select>
        <br>
      </div>
      Pro Linie einen Begriff *:<br>
      <textarea rows="10" cols="60" class="form-control" v-model="terms" required></textarea><br>
      <button type="submit" class="btn btn-primary" v-if="!loading">Eintragen</button>
      <div class="spinner-border" role="status" v-if="loading">
          <span class="sr-only">Loading...</span>
      </div>
    </form>
    <p v-if="error">{{error}}</p>
  </div>
</template>

<script>
const fb = require('../store/firebaseConfig.js')

export default {
  name: 'new',
  data() {
    return {
      selectFilled: false,
      loading: false,
      terms: '',
      list: {
        name: '',
        description: '',
        list: '',
        owner: this.$store.state.uid,
        value: [],
        created: Date.now(),
        edited: Date.now(),
        root: true
      },
      error: ''
    }
  },
  mounted () {
    let first = false;
    if (this.$store.state.actions.length === 1) {
      var option = document.createElement("option");
      option.text = this.$store.state.actions[0].name;
      option.value = this.$store.state.actions[0].id;
      if (first === false) {
        option.selected = true;
        first = true;
      }
      this.$refs.actionselect.appendChild(option);

      this.list.list = this.$store.state.actions[0].id
      console.log(this.$store.state.actions[0])
      return false
    }

    this.$store.state.actions.forEach(ele => {
      var option = document.createElement("option");
      option.text = ele.name;
      option.value = ele.id;
      this.$refs.actionselect.appendChild(option);
    })
    /* if (this.$store.state.actions.length == 0) {
      this.$router.push('/dashboard')
    } */
  },
  watch: {
    terms(newValue, oldValue) {
      this.list.value = this.terms.split('\n')
      this.list.value = this.list.value.map(ele => {
        return ele.trim()
      })
      this.list.value = this.list.value.filter(ele => {
        if (ele == "") {
          return false
        }
        return true
      })
    }
  },
  methods: {
    entryExercise() {
      this.error = '';
      if (this.list.name.trim() !== '' && this.list.list !== '' && this.list.value.length >= 3) {
        this.loading = true
        this.$store.dispatch('addList', this.list).then(() => {
          this.$router.push('dashboard');
          this.loading = false
        }).catch(() => {
          this.error = "Leider klappte etwas beim Eintragen nicht.";
          this.loading = false
        })
      } else {
        this.error = "Bitte einen Namen, eine Liste und mindestens zwei Begriffe angeben!";
      }
    },
  },
}
</script>

<style>
.form-new {
  width: 80%;
  margin: 0 auto;
}
</style>
