<template>
  <div class="search">
    <back></back>
    <div class="searchform" v-if="searchform">
      <h1>Suchen</h1>
      <input class="form-control form-control-lg" type="text" v-model="searchterm" placeholder="Suchbegriff..." @keydown.enter.prevent="search"><br>
      <button type="button" class="btn btn-primary btn-lg" @click="search">Suchen</button>
      <br>
      <br>
    </div>
    <div class="loading" v-if="loading">
      <br>
      <h3>Laden...</h3>
      <div class="spinner-border m-5" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="card-deck" v-if="resultview">
      <h1>Suchresultate</h1>
      <br>
      <div class="card" v-for="(list, ind) in results" :key="ind" @click="$router.push('/learn/' + list.id)">
        <div class="card-body">
          <h5 class="card-title">{{list.name}}</h5>
          <p class="card-text">{{list.description}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const fb = require('../store/firebaseConfig.js')

export default {
  name: 'search',
  data() {
    return {
      searchterm: '',
      searchform: true,
      loading: false,
      resultview: false,
      results: []
    }
  },
  mounted () {
    this.$store.commit('setViewTo50')
  },
  methods: {
    search() {
      this.searchform = false;
      this.loading = true;
      this.results = this.$store.state.lists.filter(itm => {
        console.log(itm);
        let name = (itm.name.toLowerCase().indexOf(this.searchterm) !== -1)
        let desc = (itm.description.toLowerCase().indexOf(this.searchterm) !== -1);
        return name || desc;
      })
      setTimeout(() => {
        console.log(this.results);
        this.loading = false;
        this.resultview = true;
      }, 1000);
    }
  },
}
</script>

<style>
.search {
    width: 60%;
    margin: 0 auto;
    text-align: center;
}

  .card-deck {
    flex-direction: column !important;
  }
</style>