<template>
  <div class="list">
      <h1>Eine Zahlung ist fällig:</h1>
      <p>Wenn du Deeperly weiter nutzen möchtest, ist die jährliche Zahlung von 5.- CHF fällig. Bitte wähle eine Zahlungsvariante und fahre mit dem Checkout fort.</p>

      <!--<button @click="checkout" class="btn btn-primary btn-lg">5.- CHF mit Kreditkarte</button>-->
    <StripeCreditCard></StripeCreditCard>
        <div v-if="showError">Leider hat etwas nicht geklappt, bitte erneut probieren!</div>
  </div>
</template>

<script>
const fb = require('../store/firebaseConfig.js')
import CreditCard from '@/components/CreditCard'
import StripeCreditCard from '@/components/StripeCreditCard'
export default {
  name: 'payment',
  data: () => ({
    loading: false,
    showError: false
  }),
  components: {
    CreditCard,
    StripeCreditCard
  },
  mounted () {
    this.$store.commit('setViewTo50')
    if (this.$route.params.err === 'error') {
      this.showError = true
    }
  },
  methods: {
  },
}
</script>

<style lang="scss">



</style>
