<template>
  <div class="list">
      <h1>Dashboard</h1>

      <div class="menu">
          <div @click="$refs['select-new'].show()" class="itm" v-b-tooltip.hover title="Eine neue Übung erstellen.">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>
          </div>
          <router-link to="/search" class="itm" v-b-tooltip.hover title="Suche">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z"/></svg>
          </router-link>
          <router-link to="/profile" class="itm" v-b-tooltip.hover title="Dein Profil">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"/></svg>
          </router-link>
          <div class="itm" @click="logout" v-b-tooltip.hover title="Logge dich aus">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 9v-4l8 7-8 7v-4h-8v-6h8zm-16-7v20h14v-2h-12v-16h12v-2h-14z"/></svg>
          </div>
      </div>
      <div class="dashboard" v-if="!$root.payment">
          <div class="list-area" v-if="$store.state.lists.length != 0">
              <div class="list-item" v-for="(list, ind) in this.$store.state.lists" :key="ind" @click.prevent="$router.push('/learn/' + list.id)">
                  <div class="list-section w20 title">
                    {{list.name}}
                  </div>
                  <div class="list-section w10">
                    <small>{{list.value.length}} Elemente</small>
                  </div>
                  <div class="list-section w40">
                    {{list.description}}
                  </div>
                  <div class="list-section">
                    <span class="list-section-small my" v-if="list.root">meine Übung</span>
                    <span class="list-section-small" v-else>fremde Übung</span>
                  </div>
                  <div class="list-section w10 tool">
                    <div class="icon" @click.stop="deleteExercise(list.id)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 6l-3 18h-12l-3-18h2.028l2.666 16h8.611l2.666-16h2.029zm-4.711-4c-.9 0-1.631-1.099-1.631-2h-5.316c0 .901-.73 2-1.631 2h-5.711v2h20v-2h-5.711z"/></svg>
                    </div>
                  </div>
              </div>
          </div>
          <div class="new-card" v-else>
              <h3>Leider sind noch keine Listen vorhanden.<br>Möchtest du eine hinzufügen?</h3>
              <span @click="$refs['select-new'].show()" class="itm">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>
              </span>
          </div>

          <div v-if="fail" class="alert alert-danger">{{fail}}</div>
          <br v-if="fail">
          <div v-if="success" class="alert alert-success">{{success}}</div>
          <br v-if="success">
      </div>
      <div class="dashboard" v-else>
        <h1> Es ist eine Zahlung fällig! <router-link to="/payment">Zahlen</router-link></h1>
      </div>

      <b-modal ref="select-new" hide-footer title="Neue Liste">
        <div class="d-block text-center">
          <h3>Was soll neu erstellt werden?</h3><br>
          <p><strong>Wörterliste:</strong> Damit erstellst du eine ganz normale Deeperly-Liste mit Begriffen.</p>
          <p><strong>Aktionen-Liste:</strong> Damit erstellst du eine neue Aktionen-Liste mit Aufgaben.</p>
        </div>
        <b-button class="mt-3" variant="outline-danger" block @click="$router.push('/new')"><br><br>Wörterliste<br><br><br></b-button>
        <b-button class="mt-2" variant="outline-primary" block @click="$router.push('/newactions')">Aktionen-Liste</b-button>
      </b-modal>

  </div>
</template>

<script>
const fb = require('../store/firebaseConfig.js')

export default {
  name: 'start',
    data() {
        return {
            lists: [],
            success: '',
            fail: ''
        }
    },
  mounted () {
    this.$store.commit('setViewTo80');
  },
  methods: {
    logout() {
      this.$store.dispatch('logOutUser').then(() => {
        this.$router.push('/');
      })
    },
    isMyExercise(listId) {
      let test = false;
      this.$store.state.lists.forEach(itm => {
        if (listId == itm.id) {
          test = true;
        }
      })
      return test;
    },
    deleteExercise(exid) {
      if (confirm('Willst du diese Übung wirklich entfernen?')) {
        this.$store.dispatch('removeList', exid).then(() => {
          this.success = "Die Liste wurde erfolgreich entfernt!"
          setTimeout(() => {
            this.success = ''
          }, 5000);
        }).catch(() => {
          this.fail = "Die Liste konnte aus unerklärlichen Gründnen nicht entfertn werden!"
          setTimeout(() => {
            this.fail = ''
          }, 5000);
        })
      }
    },
  },
}
</script>

<style lang="scss">

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .item {
    width: 90%;
    margin: 10px;
    padding: 20px;
    box-sizing: border-box;
    border: green 1px solid;
    color: green;

    &:hover {
      background-color: green;
      color: white;
      cursor: pointer;
    }
  }
}


.menu {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .itm {
    width: 46px;
    height: 46px;
    margin: 20px;
    padding: 10px;
    border-radius: 50px;
    border: black solid 1px;
    opacity: 0.7;
    position: relative;
    background-color: rgba($color: #000000, $alpha: 0);
    transition: all 0.5s ease;

    svg {
      position: absolute;
      top: 23px;
      left: 23px;
      transform: translate(-55%, -50%) scale(0.8);
    }

    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.5);
      opacity: 1;
    }

  }
}

.dashboard {
  width: 100%;
  padding: 20px;
  position: relative;

  .list-area {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .list-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    border: rgba($color: #000000, $alpha: 0.4);
    border-radius: 6px;

    padding: 10px;
    cursor: pointer;
      position: relative;

    &:hover {
      background-color: lightgrey;
      .tool {
        display: flex;
        z-index: 5;

        .icon {
          z-index: 5;
          pointer-events: all;
        }
      }

      .list-section-small {
        color: white !important;
      }
    }

    @include mobile-down {
      flex-wrap: wrap;
      flex-direction: column;
      border: gray 1px solid;
        margin-bottom: 10px;

        .tool {
            position: absolute;
            top: 60%;
            right: 10%;
        }
    }
  }

  .list-section {
    text-align: left;
    width: 20%;
      text-overflow: ellipsis;
    padding-left: 10px;
      padding-right: 10px;
      white-space: nowrap;
      overflow: hidden;

      display: inline-block;

    &.w20 {
      width: 20%;
      @include mobile-down {
        width: 100%;
      }
    }
    &.w40 {
      width: 40%;
      @include mobile-down {
        width: 100%;
      }
    }
    &.w10 {
      width: 10%;
      @include mobile-down {
        width: 100%;
      }
    }

    &.tool {
      display: none;

      flex-direction: row;
      justify-content: flex-end;
      pointer-events: none;

      .icon {
        cursor: pointer;
        display: block;
      }
    }

    &.title {
      font-weight: 800;
      font-size: 20px;
    }

    .list-section-small {
      text-transform: uppercase;
      font-size: 0.8rem;
      color: gray;

      &.my {
        color: #12dafc;
      }
    }


    @include mobile-down {
      width: 50%;
      text-align: center;
    }
  }

  .card-title {
    margin-top: 20px;
    text-transform: uppercase;
    font-size: 28px;
  }

  .card-small {
    text-transform: uppercase;
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    color: lightgray;
  }

  .new-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    h3 {
      font-size: 1.4rem;
      color:rgba(0, 0, 0, 0.6);
    }

    .itm {
      width: 46px;
      height: 46px;
      margin: 20px;
      padding: 10px;
      border-radius: 50px;
      border: black solid 1px;
      opacity: 0.7;
      position: relative;
      background-color: rgba($color: #000000, $alpha: 0);
      transition: all 0.5s ease;

      svg {
        position: absolute;
        top: 23px;
        left: 23px;
        transform: translate(-55%, -50%) scale(0.8);
      }

      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.5);
        opacity: 1;
      }

    }
  }
}

.card {
  flex-basis: 25% !important;
  margin-bottom: 25px !important;

  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.151) 0 0 20px;
  }
}
</style>
