<template>
  <div>
    <div class="list" v-if="state === 0">
      <h1>Laden...</h1>
      <p>Wir überprüfen gerade dein Abo....</p>
    </div>

    <div class="list" v-if="state === 10">
      <h1>Vielen Dank!</h1>
      <p>Danke viel Mal für die Unterstützung! Lass uns wissen, was wir besser machen können oder sende uns auch anderes Feedback, in dem du eine Mail an <a href="mailto:deeperly@codecrush.ch">uns</a> sendest.</p>
      <button @click="$router.push('/dashboard')">Weiter</button>
    </div>

    <div class="list" v-if="state === 30">
      <h1>Fehler</h1>
      <p>Leider passierte einen Fehler. Bitte überprüfe die Zahlung oder sende uns eine Mail!</p>
    </div>

  </div>
</template>

<script>

export default {
  name: 'success',
  data: () => ({
    loading: false,
    showError: false,
    state: 0
  }),
  mounted () {
    console.log("mounting success")
    this.$store.commit('setViewTo50')
    if (this.$route.params.err === 'error') {
      this.showError = true
    }

    if (this.$route.query.session_id) {
      // es gibt eine session ID
      let sessID = this.$route.query.session_id;
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer sk_test_Yq7Pimbo0xJoqhFycFY82yWA00WAg9Y8Gj");

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch("https://api.stripe.com/v1/checkout/sessions/" + sessID, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.id === sessID) {
              // good
              if (result.display_items[0].plan.active) {
                // very good
                console.log(result.subscription) // renewUserSubscription
                this.$store.dispatch('renewUserSubscription', result.subscription).then(res => {
                  if (res) {
                    this.$root.payment = false
                    this.state = 10

                  } else {
                    this.state = 30
                  }
                })
              } else {
                console.log('sorry, no valid abo')
                this.state = 30
              }
            } else {
              console.log('sorry, no valid abo')
              this.state = 30
            }
          })
          .catch(error => console.log('error', error));
    }
  },
  methods: {
  },
}
</script>

<style lang="scss">



</style>
